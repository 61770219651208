import { BASE_URL } from "../Constants";

export const postApi = async (url: string, data: object) => {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${BASE_URL}/${url}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': token ?? ''
        },
        body: JSON.stringify(data),
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer",
    });
    return response;
}