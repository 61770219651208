
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';


export const questions = new Map([
    [1, { type: 'select', label: 'How many foods did the app log?', options: [0, 1, 2, 3], key: 'foodLogged' }],
    [2, { type: 'select', label: 'How many foods did the app log correctly?', options: [0, 1, 2, 3], key: 'foodLoggedCorrectly' }],
    [3, { type: 'select', label: 'Did the app return a food recommendation?', options: ['Yes', 'No'], key: 'foodRecommended' }],
    [4, { type: 'radio', label: 'Would you consider the recommended food to be well-balanced and healthy for the average person?', options: ['Strongly Disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly Agree'], key: 'wouldRecommendToOther' }],
    [5, {
        type: 'select', label: `On a scale of 1-5 (1 being very bad and 5 being very good), please rate your experience on various responses from the App?`, options: [1, 2, 3, 4, 5], key: 'rating'
    }],
    [6, {
        type: 'select', label: `Did the app provide you with nutrition information comparing the two food items?`, options: ['Yes', 'No'], key: 'nutritionInfoProvided'
    }],
    [7, {
        type: 'select', label: `Did the app provide you with the information you asked for?`, options: ['Yes', 'No'], key: 'infoProvided'
    }],
    [8, {
        type: 'select', label: `Did the app log the weight you entered correctly?`, options: ['Yes', 'No'], key: 'weightLogged'
    }],
    [9, {
        type: 'text', label: `Ignoring any slow response time you may have encountered, please provide feedback regarding the answers provided`, key: 'feedback',
        customValidation: (val: string) => {
            return !!val && val.length >= 4;
        },
        helperText: 'Please enter between 4 to 300 characters.'
    }],
    [10, {
        type: 'select', label: 'Select your Ethnicity', options: [
            'Hispanic or Latino',
            'American Indian or Alaska Native',
            'Asian',
            'Black or African American',
            'Native Hawaiian or Other Pacific Islander',
            'White',
            'Other/Unknown',
            'Do not want to specify'
        ]
        , key: 'ethnicity'
    }],
    [11, {
        type: 'select', label: 'Gender', options: [
            'Female',
            'Male',
            'Non-Binary',
            'Do not want to specify'
        ]
        , key: 'gender'
    }],
    [12, {
        type: 'select', label: 'Age', options: [
            'below 18',
            '18 - 45',
            'above 45'
        ]
        , key: 'age'
    }]

])

export const questionsPageMap = new Map<number, number[]>([
    [0, [10, 11, 12]],
    [1, [1, 2]],
    [2, [3, 4,]],
    [3, [7,]],
    [4, [6,]],
    [5, [8, 5, 9]],
]);

export const MturkQuestions = ({ handleChange, answers, currentPage }: { answers: { [key: string]: string }, currentPage: number, handleChange: (key: string) => (event: any) => void }) => {

    return (
        <>
            {questionsPageMap.get(currentPage)?.map(question => {
                const questionSchema = questions.get(question);
                return questionSchema ?
                    <>
                        {questionSchema.type === 'select' ?
                            <FormControlLabel
                                labelPlacement='start'
                                label={questionSchema.label}
                                key={questionSchema.key}
                                sx={{ gap: '30px' }}
                                control={
                                    <Select
                                        sx={{ minWidth: '100px', marginRight: '20px' }}
                                        value={answers[questionSchema.key]}
                                        onChange={handleChange(questionSchema.key)}
                                    >
                                        {
                                            questionSchema.options?.map(m => <MenuItem key={`${questionSchema.key}_${m}`} value={m}>{m}</MenuItem>)
                                        }

                                    </Select>
                                }></FormControlLabel> :
                            <>  {
                                questionSchema.type === 'text' ?
                                    <FormControlLabel
                                        labelPlacement='top'
                                        label={questionSchema.label}
                                        key={questionSchema.key}
                                        sx={{ gap: '10px', width: '95%', alignItems: 'flex-end' }}
                                        control={
                                            <TextField
                                                id="outlined-multiline-flexible"
                                                fullWidth
                                                multiline
                                                minRows={2}
                                                helperText={questionSchema.helperText}
                                                inputProps={{
                                                    maxLength: 300,
                                                }}
                                                value={answers[questionSchema.key]}
                                                onChange={handleChange(questionSchema.key)}
                                            />

                                        }></FormControlLabel>
                                    :

                                    <FormControlLabel
                                        labelPlacement='top'
                                        label={questionSchema.label}
                                        key={questionSchema.key}
                                        sx={{ gap: '10px', alignItems: 'flex-end' }}
                                        onChange={handleChange(questionSchema.key)}
                                        control={
                                            <RadioGroup row sx={{ gap: '10px', alignSelf: 'flex-end' }}>
                                                {questionSchema.options?.map(r => <FormControlLabel key={r} value={r} control={<Radio />} label={r} />)}
                                            </RadioGroup>
                                        }></FormControlLabel>
                            }
                            </>
                        }

                    </> : null;
            })}

        </>
    );
}