import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
export const ConsentForm = ({ onConsent }: { onConsent: () => void }) => {

    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
        <Card sx={{ width: '80%', }}>
            <CardContent>
                <Typography variant="h6" color="text.secondary">
                    This HIT is part of an NSF (National Science Foundation) research project. Your decision to complete this HIT is voluntary, and your responses are anonymous.
                    The results of the research may be presented at scientific meetings, published in scientific journals, or made publicly available to other researchers.
                    Clicking on the ‘ACCEPT’ button on the bottom of this page indicates that you are at least 18 years of age, you are a native English speaker, and you agree to complete this HIT voluntarily.

                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button size="small" variant='outlined' onClick={onConsent} color='info'>Accept</Button>
            </CardActions>
        </Card>
    </div>
}