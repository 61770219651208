import { Alert, Button, Card, Skeleton, Stack, TextField } from "@mui/material"
import SendIcon from '@mui/icons-material/Send';
import { useState } from "react";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { ChatInteraction } from "./ChatInteraction";
import { ChatSample } from "./ChatSample";
import { MturkQuestions, questions, questionsPageMap } from "./MturkQuestions";
import { Message } from "./Response";
import { BASE_URL } from "./Constants";
import axios from "axios";


export const Chat = () => {
    const saveApiUrl = 'save-message';
    const [message, setMessage] = useState<string>();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState('');
    const [answers, setAnswers] = useState<any>({});
    const [responses, setResponses] = useState<Map<number, Message[]>>(new Map());
    const [currentPage, setCurrentPage] = useState<number>(0);
    const timeoutErrorMessage = 'The response is taking longer than 90 seconds, please retry again';
    const showQuestions = currentPage === 0 || responses.get(currentPage)?.some((r, index, messages) => {
        console.log(currentPage, messages);
        return messages.filter(m => m.message === timeoutErrorMessage).length > 2
            || (currentPage === 1 && messages.some(fr => { return fr.foods && Object.values(fr.foods).length > 0 }))
            || (currentPage !== 1 && messages.filter(m => m.author_id && m.message !== timeoutErrorMessage).length > 1)
    }
    );
    const queryParameters = new URLSearchParams(window.location.search);
    const turkSubmitTo = queryParameters.get('turkSubmitTo') ?? 'https://workersandbox.mturk.com';
    const actionUrl = `${turkSubmitTo}/mturk/externalSubmit`;
    const submitHandler = (event: any) => {

        const apiResponses: any[] = [];
        responses.forEach((values: Message[]) => {
            let r: any = {};
            values.forEach((resp: Message, index) => {
                if (resp.author_id !== 'chatbot' && resp.author_id !== 'error') {
                    r.userInput = resp.message;
                } else {
                    r.response = resp.message;
                    if (resp.shownFood?.length) {
                        r.foods = resp.shownFood;
                    }
                    if (resp.shownRecommendations?.length) {
                        r.recommended = resp.shownRecommendations;
                    }
                    apiResponses.push({ ...r });
                    r = {};
                }

            });
        });

        if (validateAnswered()) {
            const form = document.getElementById('form') as HTMLFormElement;
            const elem = document.createElement('input');
            elem.type = 'hidden';
            elem.name = 'assignmentId';
            elem.value = queryParameters.get("assignmentId") ?? '';
            form.appendChild(elem);


            Object.keys(answers).forEach(key => {
                const elem = document.createElement('input');
                elem.type = 'hidden';
                elem.name = key;
                elem.value = `${answers[key]}`;
                form.appendChild(elem);
            })
            const elemAnswer = document.createElement('input');
            elemAnswer.type = 'hidden';
            elemAnswer.name = 'apiResponses';
            elemAnswer.value = JSON.stringify(apiResponses);
            form.appendChild(elemAnswer);
            event.preventDefault();
            form.submit();
        }
    }

    const validateAnswered = () => {
        setError('');
        let isAllQuestionsSelectedForPage = true;
        questionsPageMap.get(currentPage)?.forEach(question => {
            const key = questions.get(question)?.key;
            const customValidation = questions.get(question)?.customValidation;
            const isCustomInvalid = !key || (!(customValidation?.(answers[key]) ?? true));
            if (isAllQuestionsSelectedForPage && (!key || !answers.hasOwnProperty(key) || isCustomInvalid)) {
                isAllQuestionsSelectedForPage = false;
            }
        });
        if (!isAllQuestionsSelectedForPage) {
            setError('Please answer all questions.');
        }
        return isAllQuestionsSelectedForPage;
    }

    const onNext = () => {
        if (validateAnswered()) {
            setCurrentPage(currentPage + 1);
        }
    }
    const saveMessage = async () => {
        setError('');
        if (!message) {
            return;
        }
        setIsLoading('loading');

        const token = sessionStorage.getItem('token');
        let response = null;
        try {
            response = await axios.post(`${BASE_URL}/${saveApiUrl}`, JSON.stringify({ message }), {
                timeout: 90000,
                timeoutErrorMessage,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': token ?? ''
                },
            });

            if (response) {
                const existingResp = responses.get(currentPage) ?? [];
                const resp = responses.set(currentPage, [...existingResp, ...response.data.messages])
                setResponses(resp);
                setIsLoading('');
                setMessage('');
            }
        } catch (e: any) {
            setIsLoading('');
            const existingResp = responses.get(currentPage) ?? [];
            const resp = responses.set(currentPage, [...existingResp, ...[{ author_id: '', message }, { author_id: 'error', message: e?.message ?? 'Error occurred, Please continue the survey' }] as Message[]]);
            setResponses(resp);
            setMessage('');
        }
    }

    return (
        <form id="form" action={actionUrl} onSubmit={submitHandler} method="post">
            <Card className="chat-container">
                <CardContent sx={{ overflow: 'auto' }}>
                    {currentPage > 0 && <>
                        <ChatSample currentPage={currentPage} />
                        <div className="chat-textbox">

                            <>
                                <TextField fullWidth
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            saveMessage();
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(event) => setMessage(event.target.value)}
                                    value={message} />

                                <Button variant="contained" type="button" color="info" onClick={saveMessage}>
                                    <SendIcon />
                                </Button>
                            </>

                        </div>
                        {
                            isLoading &&
                            <Stack spacing={1} sx={{ marginTop: 2 }}>
                                <Skeleton variant="rounded" animation="wave" height={30} />

                                <Skeleton variant="rounded" animation="wave" height={60} />
                            </Stack>
                        }
                    </>}
                    <div style={{
                        flex: 1,
                        width: '100%',
                        gap: 10, display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',

                    }}>
                        {currentPage > 0 && <ChatInteraction responses={responses.get(currentPage) ?? []} />}

                        {
                            error && <Alert severity="error">
                                {error}
                            </Alert>
                        }
                        {
                            showQuestions &&
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 30, alignItems: 'flex-end', marginTop: '30px' }}>
                                <MturkQuestions currentPage={currentPage}
                                    answers={answers}
                                    handleChange={(key) => (event) => { setAnswers({ ...answers, [key]: event.target.value }) }} />
                            </div>
                        }
                    </div>

                </CardContent>
                <CardActions sx={{ alignSelf: 'flex-end', marginRight: '10px' }}>
                    {
                        currentPage < 5 && showQuestions &&

                        <Button variant="contained" color="info" onClick={onNext}>
                            Next
                        </Button>
                    }
                    {currentPage === 5 && showQuestions &&
                        <Button variant="contained" color="info" onClick={submitHandler}>
                            Submit Survey
                        </Button>
                    }
                </CardActions>
            </Card >

        </form>

    )

}