import React, { useEffect, useState } from 'react';
import './App.css';
import { Chat } from './Chat';
import { ConsentForm } from './Consent';
import { postApi } from './api/apiHandler';
import { EMAIL, PASSWORD } from './Constants';

function App() {

  const onConsent = () => {
    sessionStorage.setItem('hasConsent', 'Y');
    setHasConsent(true);
  }


  const getToken = async () => {
    const queryParameters = new URLSearchParams(window.location.search)
    const username = queryParameters.get("workerId") ?? queryParameters.get("assignmentId");
    try {
      if (!sessionStorage.getItem('token')) {
        const response = await postApi('mturk/login',
          {
            email: EMAIL,
            password: PASSWORD,
            username
          });
        if (response.ok) {
          const data = await response.json();
          sessionStorage.setItem('token', `Bearer ${data.token}`);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    setHasConsent(sessionStorage.getItem('hasConsent') === 'Y');
  }, []);

  const [hasConsent, setHasConsent] = useState(false);
  return hasConsent ? <Chat /> : <ConsentForm onConsent={onConsent} />;
}

export default App;
