import { Alert, Chip } from "@mui/material";
import { Food, Message } from "./Response";


export const ChatInteraction = ({ responses }: { responses: Message[] }) => {

    const renderFood = (response: Message) => {
        response.shownFood = [];
        return response.foods ? <div className="foods">
            {
                Object.values(response.foods).map((food: Food) => {
                    const [match] = food.matches;
                    if (match) {
                        response.shownFood?.push([{ description: match.description, brand_name: match.brand_name, category: match.category }])
                    }
                    return match ?
                        <div className="item">
                            <div className="description"> {match.description}</div>
                            <div className="name">{match.brand_name}</div>
                            <div className="category">{match.category}</div>
                        </div> : null
                })
            }
        </div> : null;
    }

    const renderRecommendations = (response: Message) => {
        response.shownRecommendations = [];
        return response.recommendations ? <div className="recommendations">

            {
                Object.values(response.recommendations).map((match: any) => {
                    if (match) {
                        response.shownRecommendations?.push([{ description: match.description, brand_name: match.brand_name, category: match.category }])
                    }
                    return match ?
                        <div className="item">
                            <div className="description"> {match.description}</div>
                            <div className="name" >{match.brand_name}</div>
                            <div className="category">{match.category}</div>
                        </div> : null
                })
            }
        </div> : null;

    }

    return (
        <>
            {
                responses.map((response: Message, index: number) =>
                    <div key={index} style={{ alignSelf: (response.author_id === "chatbot" || response.author_id === "error") ? 'flex-start' : 'flex-end' }}>
                        {response.author_id === "error" ? <Alert severity="error">
                            {response.message}
                        </Alert> :
                            <>
                                <Chip
                                    size='medium'
                                    color={response.author_id === "chatbot" ? 'success' : response.author_id === "error" ? 'error' : 'info'}
                                    sx={{
                                        minHeight: '30px',
                                        height: 'auto',
                                        padding: '5px',
                                        '& .MuiChip-label': {
                                            display: 'block',
                                            whiteSpace: 'normal',
                                        },
                                    }}
                                    label={response.message}
                                />
                                {renderFood(response)}
                                {renderRecommendations(response)}
                            </>
                        }
                    </div>
                )

            }
        </>
    );
}