import { Paper } from "@mui/material";

export const ChatSample = ({ currentPage }: { currentPage: number }) => {
    return (
        <>
            {currentPage === 1 && <Paper elevation={1} variant="elevation" sx={{ marginBottom: '20px', backgroundColor: '#CAC4D0' }}>

                <div className="margin-left-30">
                    Using natural language to log food, tell the app 3 foods that you have recently eaten.
                    <div className="margin-left-30">
                        Be sure to include brand and full package description of each food. For example:
                        <div className="margin-left-30">
                            i. I ate a crunchy peanut butter Clif Bar, one Digiorno pepperoni stuffed crust pizza, and one Kraft mozzarella string cheese.

                        </div>

                    </div>
                    <div className="margin-left-30"> b. Log all 3 foods on one line</div>
                    <div className="margin-left-30">c. You are encouraged to use your own natural wording</div>
                    Note: Responses may take longer than expected to load - we appreciate your patience. If you happen to receive an error message, please provide your response again. Thanks.
                </div>
            </Paper>
            }
            {currentPage === 2 && <Paper elevation={1} variant="elevation" sx={{ marginBottom: '20px', backgroundColor: '#CAC4D0', alignSelf: 'flex-start', width: '100%' }}>
                <div className="margin-left-30">
                    Ask for 1 food recommendation using natural language.
                    <div className="margin-left-30">
                        a. You are encouraged to use your own natural wording. For example:
                        <div className="margin-left-30">
                            i. What should I eat?
                        </div>
                        <div className="margin-left-30">
                            ii. Recommend a healthy breakfast for me.
                        </div>
                        <div className="margin-left-30">
                            iii. Any variation of these
                        </div>
                    </div>
                    Note: Responses may take longer than expected to load - we appreciate your patience. If you happen to receive an error message, please provide your response again. Thanks.
                </div>
            </Paper>
            }
            {currentPage === 3 &&
                <Paper elevation={1} variant="elevation" sx={{ marginBottom: '20px', backgroundColor: '#CAC4D0', alignSelf: 'flex-start', width: '100%' }}>
                    <div className="margin-left-30">
                        Ask a question regarding the nutrition facts of a food you recently ate:.
                        <div className="margin-left-30">
                            a. You are encouraged to use your own natural wording. For example:
                            <div className="margin-left-30">
                                i. “How many calories are in a crunchy peanut butter Clif Bar?”
                            </div>
                            <div className="margin-left-30">
                                ii. “How many grams of fat are in a Digiorno pepperoni stuffed crust pizza?”
                            </div>
                            <div className="margin-left-30">
                                iii. “How many grams of protein is in a chicken breast?”
                            </div>
                            <div className="margin-left-30">
                                iv. Any variation of these
                            </div>
                        </div>
                        Note: Responses may take longer than expected to load - we appreciate your patience. If you happen to receive an error message, please provide your response again. Thanks.
                    </div>
                </Paper>
            }
            {currentPage === 4 && <Paper elevation={1} variant="elevation" sx={{ marginBottom: '20px', backgroundColor: '#CAC4D0', alignSelf: 'flex-start', width: '100%' }}>
                <div className="margin-left-30">
                    Ask a question comparing the nutrition content between 2 different foods:.
                    <div className="margin-left-30">
                        a. You are encouraged to use your own natural wording. For example:
                        <div className="margin-left-30">
                            i. “Does a crunchy peanut butter Clif Bar have more calories than a chicken breast?”
                        </div>
                        <div className="margin-left-30">
                            ii. “Is a Digiorno pepperoni stuffed crust pizza healthier than a Stouffer’s lasagna with meat & sauce?”
                        </div>
                        <div className="margin-left-30">
                            iii. “Does a chicken breast have more protein than a ribeye steak?”
                        </div>
                        <div className="margin-left-30">
                            iv. Any variation of these
                        </div>
                    </div>
                    Note: Responses may take longer than expected to load - we appreciate your patience. If you happen to receive an error message, please provide your response again. Thanks.
                </div>
            </Paper>
            }
            {currentPage === 5 && <Paper elevation={1} variant="elevation" sx={{ marginBottom: '20px', backgroundColor: '#CAC4D0', alignSelf: 'flex-start', width: '100%' }}>
                <div className="margin-left-30">
                    Enter your weight:
                    <div className="margin-left-30">
                        a. You are encouraged to use your own natural wording. For example:
                        <div className="margin-left-30">
                            i. “I am 160 pounds”
                        </div>
                        <div className="margin-left-30">
                            ii. “My weight is 160”
                        </div>
                        <div className="margin-left-30">
                            iii. Any variation of these
                        </div>
                    </div>
                    Note: Responses may take longer than expected to load - we appreciate your patience. If you happen to receive an error message, please provide your response again. Thanks.
                </div>
            </Paper>
            }
        </>
    );
}